

#toastContent {
	position: relative;
}

.watermark {
	position: absolute;
	left: 15px;
	bottom: 15px;
	width: 170px;
	height: auto;
	z-index: 999;
	opacity: 0.5;
	float: left;
}

@media only screen and (max-width:1200px) {
	/* CSS rules here */
	.appointmentBlurb {
		margin-top: 40px;
	}
}

.react-datepicker-wrapper {
	min-width: 200px;
}

.dateSelectors .form-control {
	font-size: 1.3em;
	font-weight: 500;
}

.calendarIcon {
	font-size: 5em;
	/* padding-left: 30px; */
}

.shopDescription {
	margin: 0 auto;
	font-size: 0.9em;
}

.shopFeeBlurb {
	padding-left: 7em;
	padding-top: 0.5em;
}


.faIcon {
	margin-right: 10px;
}

.appointments.container {
	/* margin-top: 25px; */
}

.appointments.container .form-label {
	font-size: 1.3em;
}

.scheduleButtons {
	min-height: 300px;
}

.scheduleButtons, .shopScheduleButtons div[class^='col'] {
	padding: 10px;
}

.scheduleButtons .btn {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 3.5em;
	padding: 15px 25px;
	margin-bottom: 10px;
	min-width: 300px;
}

.appointments .btn {
	background-color: #40bdce;
}

.appointments .btn:hover {
	background-color: #2d97b6;
}

.appointments .btn:active, .appointments .btn:visited, .appointments .btn:focus {
	background-color: #c1893d;
}

.shopScheduleButtons .btn {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1.5em;
	padding: 10px 15px;
	margin-bottom: 10px;
	width: 100%;
}

.locationSelect .form-control {
	max-width: 300px;
}

.locationSelect .form-label {
	margin-right: 25px;
	float: left;
}

.locationSelect {
	text-align: left;
}

.locationSelect h5 {
	padding-left: 15px;
	font-weight: 600;
	display: inline-block;
}

.locationSelect .btn {
	display: inline-block;
	margin: 0 20px;
	margin-top: -5px;
	padding-left: 25px;
	padding-right: 25px;
	text-transform: uppercase;
}

.packageCol {
	text-align: left;
}

.packageName {
	text-transform: capitalize;
	margin-top: 10px;
}

.scheduleHeading {
	font-size: 3.0em;
	margin: 0 0 10px;
}

.appointments .timeslotAppointmentDetails {
	/* min-height: 100px; */
	max-height: 300px;
	padding: 0 15px;
}

.appointments .submitButton {
	text-align: right;
}

.appointments .submitButton .btn {
	font-size: 1.5em;
	padding: 20px 40px;
	margin-top: 15px;
}

.appointments .rbc-timeslot-group {
	border: 1px solid #ddd;
}

.appointments h5 {
	margin: 1% 0;
}

h4.timeslotLabel {
	font-size: 0.5em;
	font-weight: 600;
}

.appointments .controls {
	/*padding-left: 15px;*/
}

.appointments .viewJobControls {
	text-align: center;
}

.appointments .viewJobControls .btn {
	margin: 5px 0;
	padding: 10px 15px;
	font-size: 1.5em;
}

.appointments .isomorphicCalendarWrapper {
	padding: 0;
	margin-top: 5px;
}

.appointments .dataRow div[class^="col"]:first-of-type {
	font-weight: bold;
}

/* for requested dates list in corporate lead group, i.e. "manager" mode of appointment scheduler */
.requestedDates {
	margin-right: 20px;
	text-align: right;
}

.requestedDates h4 {
	margin-bottom: 10px;
}

/* list items */
.requestedDate {
	font-size: 1.1em;
	padding: 8px;
}

.requestedDate:hover {
	background-color: #fafafa;
}

.appointments .createButton {
	text-align: left;
}

.appointments .createButton .btn {
	font-size: 1.3em;
	margin: 15px 0;
	padding: 10px 15px;
}

.appointments .errors {
	font-weight: 600;
	font-size: 1.5em;
	text-align: center;
	margin-top: 10px;
}

.appointments .locationSelectRow {
	margin-top: 10px;
}

.dateSelectors {
	text-align: center !important;
	z-index: 100 !important;
	/* border: 1px solid #c1893d; */
}

.dateSelectors label {
	padding-right: 15px;
}

.faSync {
	font-size: 2em;
}

.calendarContainer {
	padding: 0 10px;
	margin: 0;
}

.addressForm {
	/* max-width: 500px; */
	/* margin: 0 auto; */
	/* display: block; */
}
