
.toastWrapper .form-label {
	font-size: 1rem;
}

.toastWrapper #promoModal {
	max-width: 300px;
	margin: 0 auto;
}

.toastWrapper h5 {
	margin-top: 20px;
	text-transform: uppercase;
	font-weight: 600;
}

.toastStyle {
	border: 1px solid #666;
	border-radius: 10px;
	padding: 15px 25px;
	color: #fff;
	font-size: 1.9em;
	font-weight: 600;
	text-align: center;
}

.toastWrapper {
	position: fixed;
	top: 2%;
	left: 0;
	width: 100%;
	z-index: 1000 !important;
}

.toastWrapper .mainToastButton .btn {
	margin: 20px 10px 5px;
	font-size: 1.5rem;
	padding: 10px;
	border-radius: 10px;
}

.floating {
	background-color: blue;
	max-width: 700px;
	margin: 0 auto;
}

.modalToast {
	max-width: 800px !important;
	padding: 1% !important;
	margin-top: 1%;
}

.success {
	/*background-color: #5cb85c;*/
	background: #96b4c3 url('/img/success-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	border: 5px solid #C1893C;
	color: #101f42;
	font-weight: 500;
	padding: 3% !important;
}

.failure {
	background-color: #d9534f;
}

.info {
	background-color: #5bc0de;
}

.toastLabel {
	font-size: 1.1rem;
	margin-top: 20px;
}
