
.card {
	max-width: 300px;
	margin: 0 1%;
}

.contact-option {
	text-align: center;
}

/* fontawesome icons */
.contact-option .fa {
	color: #000;
}

.vehicle-form {
	width: 100%;
}

.vehicle-options {
	padding-left: 3%;
}

.vehicle-options .row {
	margin-top: 1%;
	margin-bottom: 1%;
}

.yesno-button-form * {
	margin: 5px;
}

.placeholder-img {
	background-color: black;
	min-width: 50%;
}

.customVehicleLink {
	font-size: 1.2em;
	color: #c1893d;
	cursor: pointer;
}

.section-subheading {
	margin-bottom: 1em !important;
}

@media all and (max-width: 768px) {
	
}
