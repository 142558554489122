

#CorporateQuoteConfirm {
	text-align: center;
}

@media only screen and (min-height:64em) { 
	#CorporateQuoteConfirm {
		padding: 200px 10px 300px;
	}
}

@media only screen and (max-height:64em) { 
	#CorporateQuoteConfirm {
		padding: 120px 10px 200px;
	}
}

#CorporateQuoteConfirm .faIcon {
	font-size: 1.3em;
	margin: 0 15px;
}

.faIcon.failureIcon {
	color: #d9534f;
}

.faIcon.successIcon {
	color: #5cb85c;
}

.loadingAnimation {
	width: 100%;
	text-align: center;
	padding: 15px;
}

.loadingAnimation h4 {
	display: inline-block;
	/* align heading with spinner */
	position: relative;
	bottom: 10px;
}

.loadingAnimation .spinner-grow {
	width: 40px;
	height: 40px;
	margin: 0 20px;
}
