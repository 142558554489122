
.watermark {
	position: absolute;
	left: 15px;
	bottom: 15px;
	width: 170px;
	height: auto;
	z-index: 999;
	opacity: 0.5;
	float: left;
}

.promoError {
	color: #FFFFFF;
	background-color: #721c24;
	border-radius: 5px;
	text-align: center;
	padding: 8px;
	margin: 10px 0 5px;
}

.promoError h5 {
	margin: 0;
}

.promoCodeInput {
	max-width: 350px;
}

#toastContent.success .timeHours {
	font-weight: 600;
	padding: 0 1em;
}
#toastContent.success .timeHours:first-child {
	border-right: 2px solid;
}
#toastContent.success .timeHours:last-child {
	border-left: 2px solid;
}

@media all and (max-width: 768px) {
	#toastContent.success .timeHours {
		border: 0 !important;
		padding: 0;
		display: block;
	}
}

#LeadForm h1 {
	font-size: 3em;
	display: inline-block;
}

#LeadForm .backButton {
	float: right;
	margin-top: 10px;
	margin-right: 25px;
}

.backButton .faIcon, .faBackIcon {
	margin-right: 10px;
}

.card {
	max-width: 300px;
	margin: 0 1%;
}

.contact-option {
	text-align: center;
}

/* fontawesome icons */
.contact-option .fa {
	color: #000;
}

.vehicle-form {
	width: 100%;
}

.vehicle-options {
	padding-left: 3%;
}

.vehicle-options .row {
	margin-top: 1%;
	margin-bottom: 1%;
}

.yesno-button-form * {
	margin: 5px;
}

.placeholder-img {
	background-color: black;
	min-width: 50%;
}

.customVehicleLink {
	font-size: 1.2em;
	color: #c1893d;
	cursor: pointer;
}

.white-bg {
	background-color: #FFF;
	padding: 10px;
	border-radius: 4px;
}

.getQuoteSubmitButton {
	font-size: 1.7em;
	padding: 10px 20px;
	margin-top: 10px;
}

@media all and (max-width: 768px) {
	
}
