
.timeslotAppointmentDetails div[class^='col'] {
	text-align: left;
}

.scheduleContainer {
	padding-top: 30px;
}

.scheduleContainer h1 {
	font-size: 3em;
	margin-bottom: 15px;
}
