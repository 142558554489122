body {
  background-color: #FFF;
  font-family: 'Roboto', sans-serif;
}

/* new color theme */
.btn-primary, .atn-btn-primary {
  color: #fff;
  /* background-color: #0F1F41; */
  background-color: #40bdce;
  /* border-color: #0F1F41; */
  border-color: #40bdce;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #2d93b0;
  border-color: #2d93b0;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  /* background-color: #0F1F41; */
  background-color: #40bdce;
  /* border-color: #0F1F41; */
  border-color: #40bdce;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #c1893d;
    border-color: rgba(0,0,0,0.3);
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #E4AF66;
}

#LeadForm {
  /*background: #FFF url('/img/about_btm.png') center center fixed no-repeat;*/
}

#SurveyForm {
  /*background: #FFF url('/img/about_btm.png') center center fixed no-repeat;*/
}

.section-heading {
  margin-top: 0.8em;
  margin-bottom: 20px;
  color: #c1893d;
  font-weight: 700;
  font-size: 24px;
}

.section-subheading {
  margin-bottom: 35px;
  color: #404040;
  font-weight: 500;
  font-size: 18px;
}

.fieldset-indent {
  width: 100%;
  padding: 0px 30px 0px 30px;
  /*border-left: 1px dashed #55549F;*/
  transition: border 0.35s;
}

.list {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 20px;
  padding: 0;
}

.list-item {
  padding: 0px 5px 10px 5px;
  text-decoration: none;
  list-style-type: none;
  /*width: 25%;*/
  display: flex;
}

.list-item.repair-option {
  /*width: 50%;*/
}

.list-item label {
  position: relative;
  cursor: pointer;
  display: block;
  border-radius: 3px;
  background-color: #0f1f41;
  padding: 0px 0px 0px 0px;
  border: 1px solid #a6a6a6;
  overflow: hidden;
  width: 100%;
  transition: all 0.35s;
  padding: 5px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.5);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.5);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5);
  /*font-family: "Gotham SSm A", "Gotham SSm B";*/
  font-style: normal;
  font-weight: 400;
}

.list-item.selected label {
  background-color: #C1893D;
  border: 1px solid #848484;
}

.list-item label .bg-image {
  /*background-color: white;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
}

.list-item label img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 1px;
  -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
  pointer-events: none;
}

.contactOptions .list-item label img {
	height: 110px;
}

.list-item label input[type="radio"] {
  height: 0px;
  width: 0px;
  overflow: hidden;
  display: block;
  visibility: hidden;
}

.list-item label span {
  padding: 11px 15px 7px 15px;
  display: block;
  color: #FFF;
  font-size: 87.5%;
}

.list-item label .selected {
  display: none;
  position: absolute;
  top: -30px;
  right: -30px;
  background: #55549F;
  color: white;
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
  -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}

.list-item label .selected svg {
  transform: rotate(-45deg);
  /*padding: 31px 32px 0px 0px;*/
  margin-bottom: -36px;
}

.list-item.selected label .selected {
  display: block;
}

#page_outer.my-blue-bleed {
  background-color: #16356C;
  background-image: url('/img/bleed-blue.jpg')!important;
  background-size: cover!important;
  background-position: top center!important;
  background-repeat: no-repeat!important;
  background-attachment: fixed;
  color: #FFF;
}

#page_outer.my-blue-bleed .section-subheading {
  color: #FFF;
}

#page_outer.my-blue-bleed .list-item label {
  border: 1px solid rgba(0,0,0,0.3);
}

#footer_btm {
  background: rgb(15 31 65 / 0.75);
  /*background: #0f1f41;*/
  /*padding: 72px 0 33px 0;*/
  padding: 33px 0 33px 0;
}
#footer_btm a { color: #fff; }
#footer_btm .disclaimer { font-size: 0.9em; }
.ftr_menu ul { margin: 0; list-style: none; }
.ftr_menu ul li a { color: #fff; font-size: 16px; line-height: 30px; }

#ftr_copy {
    background: #060e1e;
    padding: 20px 0;
}

#ftr_copy p {
  margin-bottom: 0;
  color: #fff;
  padding-top: 14px;
}
#ftr_copy a {
    color: #fff;
    /*float: right;*/
}

#footer_btm table td {
  vertical-align: top;
}
#footer_btm #social_icon a {
  color: #fff;
  font-size: 22px;
  margin-right: 16px;
}

img.invisible-log {
    width: 85px;
    margin-right: 10px;
}

@media (max-width: 991px) and (min-width: 768px) {
  #ftr_copy p, #ftr_copy a {
    font-size: 13px;
  }
}